import React from 'react'

import Layout from '../layouts/layout'
import Seo from '../components/seo'
import BlueHeader from '../components/UI/Header/BlueHeader'
import GuidesIndex from '../components/ForGuides/GuidesIndex'

const Guides = ({ path }) => {
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Article",
    "headline": "SocietyOne Guides",
    "datePublished": "2022-04-05",
  }
  return (
    <Layout>
      <Seo 
        title="Personal Finance Guides" 
        description="Discover SocietyOne's guides to learn everything on personal loans, credit scores, debt consolidation, budgeting and more." 
        path={path}
        structuredData={structuredData}
      />
      <BlueHeader headerText="SocietyOne Guides" />
      <GuidesIndex />
    </Layout>
  )
}

export default Guides
