import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import { BiTimeFive } from 'react-icons/bi'
import ContentContainer from '../UI/ContentContainer'
import ImageCard from '../UI/Card/ImageCard'

const GuidesIndex = () => {
  const allGuidesArticleComps = useStaticQuery(
    graphql`
      query allGuidesPage {
        allContentfulPageGuide(
          filter: { tag: { eq: "Guide" } }
          sort: { fields: order, order: ASC }
        ) {
          nodes {
            heroImage {
              gatsbyImageData(width: 400)
              title
            }
            heading {
              childMarkdownRemark {
                html
              }
            }
            subheading {
              childMarkdownRemark {
                html
              }
            }
            additionalText
            order
            slug
          }
        }
      }
    `
  )

  return (
    <ContentContainer>
      <Guides>
        {allGuidesArticleComps.allContentfulPageGuide.nodes.map((a, i) => (
          <ImageCard
            key={i}
            to={`/guide/${a.slug}`}
            titleHtml={a.heading.childMarkdownRemark.html}
            imgPath={a.heroImage?.gatsbyImageData}
            imgAlt={a.heroImage.title}
            width="400px"
            background="linear-gradient(45deg,#172d3d,#2c5571)"
            textColor="white"
            imgHeight="280px"
            imgStyleOnHover="transform: scale(1.05);
                                transition: 0.6s;"
          >
            <div
              dangerouslySetInnerHTML={{
                __html: a.subheading.childMarkdownRemark.html,
              }}
            />
            <p className="readtime">
              <BiTimeFive />
              {a.additionalText}
            </p>
          </ImageCard>
        ))}
      </Guides>
    </ContentContainer>
  )
}

const Guides = styled.div`
  display: grid;
  grid-gap: 56px;
  justify-items: center;
  > div {
    margin: 0;
  }
  .readtime {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 56px;
    svg {
      margin-right: 8px;
    }
  }
  @media screen and (min-width: 990px) {
    grid-template-columns: repeat(2, 1fr);
    div:nth-child(odd) {
      justify-self: end;
    }
    div:nth-child(even) {
      justify-self: start;
    }
  }
  @media screen and (max-width: 479px) {
    > div {
      width: 100%;
    }
  }
`

export default GuidesIndex
